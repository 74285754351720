<template>
  <main-layout>
    <library-hours/>

    <history/>
    <contacts/>
<!--    <news/>-->
<!--    <president-message/>-->
    <services/>
  </main-layout>
</template>

<script>


import MainLayout from "../layouts/MainLayout";
import LibraryHours from "../widgets/LibraryHours";
// import PresidentMessage from "../widgets/PresidentMessage";
import Contacts from "../widgets/Contacts";
import History from "../widgets/History";
// import News from "../widgets/news/News";
import Services from "../widgets/Services";
export default {
  name: 'Home',
  components: {Services, History, Contacts, LibraryHours, MainLayout},
}
</script>
