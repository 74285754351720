<template>
   <section id="contacts" class="bg-gray-700 bg-opacity-80">
       <container class="py-10 px-8">
           <div>
               <h1 class="text-white md:text-5xl  text-4xl mg:text-5xl font-extralight"><span class="text-green-600 font-bold">|</span>For inquires, you may contact or visit us: </h1>
           </div>
           <div class=" grid lg:grid-cols-3 justify-center py-20">
              <div class="lg:col-span-1" v-for="(contact,index) in contacts" v-bind:key="index">
                  <div class="my-6 text-center">
                      <p class="text-green-600 text-2xl block font-semibold">{{contact.name}}</p>
                  </div>
                  <div  v-for="(info,index) in contact.infos" v-bind:key="index" class="grid grid-cols-3 lg:grid-cols-2 xl:text-lg text-base text-gray-200">
                      <div class="lg:col-span-1 col-span-1 text-right">
                          <p class="italic">{{info.type}}:</p>
                      </div>
                      <div class="lg:col-span-1 col-span-2 pl-2">
                          <p v-for="(detail,index) in info.details" v-bind:key="index" class="">{{detail}}</p>
                      </div>
                  </div>
              </div>
           </div>
       </container>
   </section>
</template>

<script>
    import Container from "../layouts/Container";
    export default {
        name: "Contacts",
        components: {Container},
        data:() => ({
            contacts:[
                {
                    name: 'Main Campus',
                    infos:[
                        {
                            type: 'Contact No. ',
                            details: ['09660282433'],
                        },
                        {
                            type: 'Address',
                            details: ['Alcate, Victoria, Oriental Mindoro'],
                        },
                        {
                            type: 'Email',
                            details: ['caaflibrary@gmail.com'],
                        },
                        {
                            type: 'Facebook Page',
                            details: ['MinSU College of Agriculture and Allied Fields Library','MinSU Graduate Studies Library',],
                        }
                    ]
                },
                {
                    name: 'Bongabong Campus',
                    infos:[
                        {
                            type: 'Contact No. ',
                            details: ['09550884683'],
                        },
                        {
                            type: 'Address',
                            details: ['Labasan, Bongabong, Oriental Mindoro'],
                        },
                        {
                            type: 'Email',
                            details: ['minsubongabonglibrary@ gmail.com'],
                        },
                        {
                            type: 'Facebook Page',
                            details: ['Mindoro State University - Bongabong Campus Library'],
                        }
                    ]
                },
                {
                    name: 'Calapan City Campus ',
                    infos:[
                        {
                            type: 'Contact No. ',
                            details: ['09486212426'],
                        },
                        {
                            type: 'Address',
                            details: ['Masipit, Calapan City, Oriental Mindoro'],
                        },
                        {
                            type: 'Email',
                            details: ['minscatccclibrary.2017@ gmail.com'],
                        },
                        {
                            type: 'Facebook Page',
                            details: ['Mindoro State University Library - Calapan City Campus'],
                        }
                    ]
                },
            ]
        })

    }
</script>

<style scoped>

</style>