<template>
  <section id="history" class="pt-20  bg-green-700 bg-opacity-80">
      <div  class="lg:py-20 py-4 bg-white">
        <container class="md:p-10 p-4">
          <div class="md:flex">
            <div class="md:flex-1 p-6">
              <div class="flex justify-center items-center">
                <h1 class="md:text-7xl text-4xl font-extralight">Want to know the latest about our <a class="uppercase text-green-700 font-semibold" >campus libraries</a>?</h1>
              </div>
            </div>
            <div class="md:flex-1 ">
              <div class="relative">
                <a  title="Main Campus" href="https://www.facebook.com/minsumainlib">
                  <div class="history-card overflow-hidden md:w-96 w-72 m-4 md:left-12 md:-bottom-10 md:z-0 md:absolute shadow-xl rounded-2xl border-4 border-green-700 block">
                    <img class="w-full h-full" src="../../assets/images/library-building/main-building.jpg" alt>
                    <div class="absolute w-full flex justify-center items-center bottom-10 bg-green-700 bg-opacity-70 text-white text-xl">
                      <h3>Main Campus</h3>
                    </div>
                  </div>
                </a>
                <a  title="Bongabong Campus" href="https://www.facebook.com/minsubclib">
                  <div class="history-card overflow-hidden md:w-96  w-72 m-4 md:-bottom-96 md:z-0 md:absolute shadow-xl rounded-2xl border-4 border-green-700 block">
                    <img class="w-full h-full" src="../../assets/images/library-building/bongabong-building.jpg" alt>
                    <div class="absolute w-full flex justify-center items-center bottom-10 bg-green-700 bg-opacity-70 text-white text-xl">
                      <h3>Bongabong Campus</h3>
                    </div>
                  </div>
                </a>
                <a title="Calapan City Campus" href="https://www.facebook.com/minsucclib">
                  <div class="history-card overflow-hidden md:w-96 sm:absolute  sm:z-0 sm:right-0 w-72 m-4 md:right-0 md:bottom-18 md:z-5 md:absolute shadow-xl rounded-2xl border-4 border-green-700 block">
                    <img class="w-full h-full" src="../../assets/images/library-building/calapan-building.jpg" alt>
                    <div class="absolute w-full flex justify-center items-center bottom-10 bg-green-700 bg-opacity-70 text-white text-xl">
                      <h3>Calapan City Campus</h3>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </container>
      </div>
    </section>
</template>

<script>
import Container from "../layouts/Container";
    export default {
        name: "History",
        components: {Container}
    }
</script>

<style scoped>
.history-card{
  @apply transform hover:rotate-2 hover:shadow-xl  hover:scale-105 transition duration-500 ease-in-out;
}

</style>