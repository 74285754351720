<template>
  <section class="flex justify-center text-white ">
    <div v-for="(campus,index) in campuses" v-bind:key="index" class="mx-4 bg-green-700 w-20 h-20  transform hover:shadow-2xl hover:rotate-3 hover:border-green-700 hover:scale-110 hover:-translate-y-2 transition duration-500 ease-in-out shadow-lg  rounded-lg overflow-hidden  border-2 border-white w-56 h-32 relative">
      <a :href="campus.video_link" target="_blank">
        <img class="w-full h-full object-cover object center" :src="campus.img_link" alt="">
        <div class="bg-green-700 bg-opacity-80 absolute bottom-0 w-full flex justify-center text-center">
          <h1 class="lg:text-lg text-xs lg:uppercase font-semibold my-2">{{campus.name}}</h1>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
export default {
name: "Campuses",
  data: () => ({
    campuses: [
      {
        name: 'Main Campus',
        img_link: require(`@/assets/images/library-building/main-building.jpg`),
        video_link: 'https://www.youtube.com/watch?v=-zOPV85qNjM',
      },
      {
        name: 'Calapan City Campus',
        img_link: require(`@/assets/images/library-building/calapan-building.jpg`),
        video_link: 'https://www.youtube.com/watch?v=GJmQNSAK-Xo',
      },
      {
        name: 'Bongabong Campus',
        img_link: require(`@/assets/images/library-building/bongabong-building.jpg`),
        video_link: 'https://www.youtube.com/watch?v=j5EnZmvBJE4',
      },
    ]
  })
}
</script>

<style scoped>

</style>