<template>
  <div class="border-t-8 border-green-700 bg-gray-700 py-6 px-2 ">
<!--    <div class="flex justify-center">-->
<!--      <h class="text-3xl text-green-700 font-semibold">{{ abbreviation }} Upholds</h>-->
<!--    </div>-->
<!--    <div class="flex justify-center my-6">-->
<!--      <h class="text-5xl text-gray-200 font-semibold uppercase">-->
<!--        R<small class="text-green-700">esponsibility</small>-->
<!--        I<small class="text-green-700">nvolvement</small>-->
<!--        C<small class="text-green-700">omitment</small>-->
<!--        E<small class="text-green-700">xcellence</small>-->
<!--      </h>-->
<!--    </div>-->
    <div class="text-center  text-gray-300 italic">
      <span class="block">Managed and Maintained by Management Information System Office</span>
      <span>Copyright© 2021 | All Rights Reserved | {{title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  computed:{
    title(){
      return this.$store.state.title;
    },
    abbreviation(){
      return this.$store.state.abbreviation;
    }
  }
}
</script>

<style scoped>

</style>