<template>
  <nav class=" text-white ">
    <div class="bg-green-700 w-full h-96 relative ">
      <div  class="absolute w-full z-10">
        <top-bar/>
      </div>
      <div class="bg-green-700">
        <img  class="w-full h-96 object-cover object-center opacity-50" src="../../assets/images/library-building/banner.jpg" alt="">
      </div>
      <div>
        <div class="absolute top-0 h-full items-center flex w-full">
          <div class="w-full text-center">
            <div class="md:flex bg-white bg-opacity-40 py-4 p-4">
              <div class="flex md:hidden w-full items-center justify-center">
                <img class="w-10" src="../../assets/images/logo/minscat.png" alt="">
              </div>
              <div class="md:flex-1 flex justify-center md:justify-end md:text-right text-center items-center mr-4 lg:pl-20">
                <h1 class="uppercase sm:text-3xl lg:text-4xl text-white text-2xl font-extralight font-serif ">
                 {{title}}
                </h1>
              </div>
              <div class="md:flex hidden md:block flex items-center text-center justify-center  m-4">
                <img class="animate-bounce sm:w-20 sm:w-20 w-12 w-12 block object-center" src="../../assets/images/logo/minscat.png" alt="">
              </div>
              <div class="md:flex-1 justify-center md:justify-start md:text-left pl-2 flex items-center">
                <h1 class="uppercase text-green-700 sm:text-6xl md:text-7xl text-5xl lg:text-7xl font-bold   ">
                  Library
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  class="absolute bottom-0 w-full -mb-20">
          <campuses/>
      </div>
    </div>
  </nav>

</template>

<script>
import TopBar from "./TopBar";
import Campuses from "../../views/widgets/Campuses";
export default {
  name: "TitleBar",
  components: {Campuses, TopBar},
  computed:{
    title(){
      return this.$store.state.title;
    }
  }
}
</script>

<style scoped>

</style>