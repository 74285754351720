<template>
    <section id="library-hours" class="bg-white  py-28">
        <container>
            <div class="lg:grid lg:grid-cols-2">
                <div class="lg:col-span-1 p-10 flex lg:justify-end justify-center  items-center">
                      <div class="lg:text-right text-center">
                          <h1 class="uppercase lg:text-5xl text-4xl font-extralight "><span class="text-green-700 lg:text-7xl text-5xl font-bold">Library |</span> Hours</h1>
                          <div class="my-10 ">
                              <p class="lg:text-4xl text-2xl italic">Monday to Friday</p>
                              <div class="px-4 block">
                                  <p class="uppercase lg:text-5xl text-2xl">7:00 a.m. to 6:00 p.m</p>
                              </div>
                            <p class="lg:text-4xl text-2xl italic"> & Saturday</p>
                            <div class="px-4 block">
                              <p class="uppercase lg:text-5xl text-2xl">8:00 a.m. to 5:00 p.m</p>
                            </div>
                              <div class="flex lg:justify-end justify-center my-4">
                                  <h1 class="lg:text-5xl text-4xl">NO</h1>
                                  <d><h1 class="text-green-700 lg:text-5xl text-4xl mx-2">|</h1></d>
                                  <div class="ml-2 text-left">
                                      <p class="md:text-xl text-base lg:font-semibold">Break time</p>
                                      <p class="md:text-xl text-base lg:font-semibold">Lunch break</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                </div>
                <div class="lg:col-span-1 p-10 flex justify-center items-center">
                    <div class="relative py-3 sm:max-w-xl w-full sm:mx-auto   @apply transform hover:rotate-2 hover:shadow-xl  hover:scale-105 transition duration-500 ease-in-out">
                        <div class="absolute inset-0 bg-gradient-to-r from-green-600 to-green-700 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl sm:rounded-3xl"></div>
                        <div class="relative bg-white shadow-lg rounded-3xl">
                            <div class="max-w-md mx-auto p-2  h-80 rounded-3xl overflow-hidden">
                                <iframe class="lg:rounded-xl rounded-3xl" width="100%" height="100%" src="https://www.youtube.com/embed/_0rfZxScepI" title="Mindoro State University Video Primer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </container>
    </section>
</template>

<script>
    import Container from "../layouts/Container";
    export default {
        name: "LibraryHours",
        components: {Container}
    }
</script>

<style scoped>

</style>