<template>
    <section class="py-20 bg-white ">
        <container>
            <div class="flex justify-center item-center grid md:col-span-3 xl:grid-cols-4 md:px-20">
                <div class="logo-div">
                    <img class="logo" src="../../assets/images/services/opac.png" alt="">
                </div>
                <div class="logo-div">
                    <img class="logo" src="../../assets/images/services/follet.png" alt="">
                </div>
                <div class="logo-div">
                    <img class="logo" src="../../assets/images/services/starbooks.png" alt="">
                </div>
                <div class="logo-div">
                    <img class="logo" src="../../assets/images/services/plagscan.png" alt="">
                </div>
            </div>
        </container>
    </section>
</template>

<script>
    import Container from "../layouts/Container";
    export default {
        name: "Services",
        components: {Container}
    }
</script>

<style scoped>
    .logo{
        @apply h-16 transform hover:scale-125 transition duration-500 ease-in-out;
    }
    .logo-div{
        @apply m-6 col-span-1 flex justify-center items-center;
    }
</style>