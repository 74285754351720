<template>
<div>
  <title-bar/>
  <slot/>
  <footer-bar/>
</div>
</template>

<script>
import TitleBar from "../../components/core/TitleBar";
import FooterBar from "../../components/core/FooterBar";
export default {
name: "MainLayout",
  components: {FooterBar, TitleBar}
}
</script>

<style scoped>

</style>